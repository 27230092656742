/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
/*
    require('bootstrap');
    window.jQuery = window.$ = require('jquery');

    require('./site/js/vendor/modernizr-3.8.0.min');
    require('./site/js/jquery.min');
    window.jQuery = window.$ = require('jquery');
    require('./site/js/plugins');
    require('./site/js/main');

    const Swal = require('sweetalert2')
    const lightSlider = require('lightslider');
    const Plyr = require('./site/js/plyr.min.js');
    import select2 from 'select2';
    import Splitting from './site/js/splitting.js';
    import Odometer from 'odometer';
    import MultiSelect from './site/js/multiselect.js';
    require('bootstrap-datepicker');
*/

const popupModal = document.querySelector(".popup--modal");
if (popupModal != null) {
  const showPopup = localStorage.getItem("show-popup");
  const now = new Date();
  if (showPopup != null) {
    const lastShowDate = new Date(showPopup);
    console.log("lastShowDate", lastShowDate);
    if (lastShowDate.getTime() < now.getTime()) {
      popupModal.classList.add("show");
      const addDay = new Date(now.getTime() + 60 * 60 * 60000);
      localStorage.setItem("show-popup", addDay);
    }
  }

  const backDrop = document.querySelector(".popup--modal .modal-backdrop");
  if (backDrop != null) {
    backDrop.addEventListener("click", () => {
      popupModal.classList.remove("show");
    });
  }
}
